import React, { useState } from "react"
import { backdrop, banner, buttons, copy, details, essential, headline, label, message, primary, service, services, title, wrapper } from './index.module.scss'

import TextRenderer from '../text/renderer'
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies'
import { useLocation } from "@reach/router"

const BANNER_HIDDEN_KEY = 'ut-hide-cookie-banner'
const ESSENTIALS_KEY = 'ut-allow-cookies'
const GOOGLE_ANALYTICS_KEY = 'gatsby-gdpr-google-analytics'

const CookieBanner = (props) => {
  // do not ssr
  if (typeof document === 'undefined') return null
  return <CookieBannerComponent {...props}/>
}

const CookieBannerComponent = ({ currentLocale }) => {
  const getCookies = () => {
    const availableCookies = {}
    document.cookie.split(/; |;/).map(cookie => {
      const split = cookie.split('=')
      return availableCookies[split[0]] = split[1]
    })
    return availableCookies
  }

  const location = useLocation()
  const [allowGoogleAnalytics, setAllowGoogleAnalytics] = useState(false)
  const [showDetails, setShowDetails] = useState(false)
  const [forceHideBanner, setforceHideBanner] = useState(false)

  const availableCookies = [BANNER_HIDDEN_KEY, ESSENTIALS_KEY, GOOGLE_ANALYTICS_KEY]

  const cookies = getCookies()
  const setCookie = (name, value, days) => {
    const d = new Date()
    d.setTime(d.getTime() + 24*60*60*1000*days)
    document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString()
  }

  const hideBanner = cookies[BANNER_HIDDEN_KEY] === 'true' && !availableCookies.map(cookie => !!cookies[cookie]).includes(false)

  // hide the banner if there is a cookie and it is true
  if (forceHideBanner || hideBanner) return null

  const sections = [
    {
      headline: 'Technisch Notwendige Cookies',
      description: 'Notwendige Cookies ermöglichen grundlegende Funktionen und sind für das einwandfreie Funktionieren der Website notwendig.',
      action: () => {},
      status: undefined
    },
    {
      headline: 'Google Analytics',
      description: 'Zur Website-Optimierung erheben wir Daten, die bereits für die technische Bereitstellung des Webangebots erforderlich sind. Solche Daten werden ausschließlich aggregiert und uns als statistische Übersicht zur Verfügung gestellt.',
      action: () => setAllowGoogleAnalytics(!allowGoogleAnalytics),
      status: allowGoogleAnalytics
    }
  ].map((section, index) => {
    return (
      <div key={index} className={service}>
        <label className={label}>
          {( section.status === undefined ?
            <input type='checkbox' checked={true} disabled/> :
            <input type='checkbox' checked={section.status} onChange={section.action}/>
          )}
          <div>
            <div className={headline}>{section.headline}</div>
            <div className={copy}>
              <TextRenderer text={section.description}/>
            </div>
          </div>
        </label>
      </div>
    )
  })

  const acknowledgeSelection = () => {
    setCookie(BANNER_HIDDEN_KEY, true, 90)
    setCookie(ESSENTIALS_KEY, true, 90)
    setCookie(GOOGLE_ANALYTICS_KEY, allowGoogleAnalytics, 90)

    initializeAndTrack(location)
    setforceHideBanner(true)
  }

  const acknowledgeAll = () => {
    setCookie(BANNER_HIDDEN_KEY, true, 90)
    setCookie(ESSENTIALS_KEY, true, 90)
    setCookie(GOOGLE_ANALYTICS_KEY, true, 90)

    initializeAndTrack(location)
    setforceHideBanner(true)
  }

  return (
    <>
      <div className={wrapper}>
        <div className={banner}>
          <div className={title}>Wir benutzen Cookies!</div>
          <div className={message}>
            <TextRenderer text={'Um unsere Webseite für Sie optimal zu gestalten, verwenden wir Cookies, Google Analytics und Dienste von Drittanbietern wie bspw. YouTube. Durch die weitere Nutzung der Webseite stimmen Sie der Verwendung von Cookies und der Analyse Ihrer Daten zu. Weitere Informationen erhalten Sie in unserer [Datenschutzerklärung](/de/datenschutz).'}/>
          </div>
          <button className={details} onClick={() => setShowDetails(!showDetails)}>
            Einstellungen anzeigen
          </button>
          <div className={services} data-shown={showDetails}>{sections}</div>
          <div className={buttons}>
            <button type="button" className={essential} onClick={acknowledgeSelection}>Technisch notwendige akzeptieren</button>
            <button type="button" className={primary} onClick={acknowledgeAll}>Alle akzeptieren</button>
          </div>
        </div>
      </div>
      <div className={backdrop}/>
    </>
  )
}


export default CookieBanner
