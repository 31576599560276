import React, { useEffect, useState } from "react"
import { bar, button, header, link, linkActive, logo, quick, toggleActive, toggleDefault } from './index.module.scss'
import { graphql, useStaticQuery } from "gatsby"

import HeaderNavigation from './navigation'
import { Link } from 'gatsby'
import logoImage from '../../images/logo.svg'
import { useLocation } from "@reach/router"

const Header = () => {
  const [showNavigation, setShowNavigation] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setShowNavigation(false)
  }, [location.pathname])

  const { quickNavigation } = useStaticQuery(
    graphql`
      {
        quickNavigation: allQuickJson {
          edges {
            node {
              primary
              link
              label
            }
          }
        }
      }
    `
  )

  const toggleNavigation = () => {
    setShowNavigation(!showNavigation)
  }

  return (
    <>
      <header className={header}>
        <div className={logo}>
          <Link to='/'>
            <img src={logoImage} alt='Unleashed Therapy'/>
          </Link>
        </div>
        <div className={quick}>
          {quickNavigation?.edges.map(({ node: item }) => (
            <Link key={item.link} to={item.link} className={item.primary ? button : link} activeClassName={linkActive}>{item.label}</Link>
          ))}
        </div>
        <button className={showNavigation ? toggleActive : toggleDefault} onClick={toggleNavigation} aria-label="Menü öffnen">
          <div className={bar}/>
          <div className={bar}/>
          <div className={bar}/>
        </button>
      </header>
      { showNavigation && <HeaderNavigation/> }
    </>
  )
}

export default Header
