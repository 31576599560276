import { Link, graphql, useStaticQuery } from "gatsby"
import { contact, container, copyright, footer, infos, legal, link, meta, navigation, navigationItem } from './index.module.scss'

import React from "react"
import logoImage from '../../images/logo-heart-tree.svg'
import Bar from '../bar'

const Footer = () => {
  const { legalNavigation, footerNavigation } = useStaticQuery(
    graphql`
      {
        legalNavigation: allLegalJson {
          edges {
            node {
              link
              label
            }
          }
        }

        footerNavigation: allFooterJson {
          edges {
            node {
              link
              label
            }
          }
        }
      }
    `
  )

  return (
    <footer className={footer}>
      <div className={container}>
        <Bar width='33vw' color='#fff' />
        <div className={infos}>
          <div className={contact}>
            <img src={logoImage} alt="Unleashed Therapy" />
            <p>
              <b>Unleashed Therapy</b><br />
              David Witton<br />
              <br />
              <a href='mailto:David Witton <david@unleashed-therapy.com>'>david@unleashed-therapy.com</a><br />
              +49 162 3979797<br />
              <br />
              Grube Königsberg<br />
              35444 Biebertal<br />
              <br />
              <a href="https://www.instagram.com/unleashedtherapy" rel="noreferrer" target="_blank">instagram.com/unleashedtherapy</a>
            </p>
          </div>
          <nav className={navigation}>
            {footerNavigation?.edges.map(({ node: item }) => (
              <Link key={item.link} to={item.link} className={navigationItem}>{item.label}</Link>
            ))}
          </nav>
          <div className={navigation}>
            Wir sind offizieller Partner von <a href="https://edubily.de">edubily</a>
            <a href="https://edubily.de">
              <img src="/edubily-white.png" alt="edubily" width={100} style={{ marginTop: "16px" }} />
            </a>
          </div>
        </div>
        <div className={meta}>
          <nav className={legal}>
            {legalNavigation?.edges.map(({ node: item }) => (
              <Link key={item.link} to={item.link} className={link}>{item.label}</Link>
            ))}
          </nav>
          <div className={copyright}>
            © Copyright {new Date().getFullYear()}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
