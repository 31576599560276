import { graphql, useStaticQuery } from "gatsby"

import Helmet from "react-helmet"
import React from "react"

function Seo({ title, metaTitle, metaDescription, sharingTitle, sharingDescription="Im Mittelpunkt unserer Zusammenarbeit stehen ganzheitliches Training und Therapie. Wir behandeln nicht die Symptome sondern die Ursache. Dabei ist die Neuro-Athletik ein wichtiger Bestandteil, denn: Bewegung entsteht im Gehirn.", sharingImage, currentUrl, lang, keywords }) {

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteTitle: title
            siteUrl
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s - ${site.siteMetadata.siteTitle}`}
      link={[
        {
          href: site.siteMetadata.siteUrl + currentUrl,
          rel: "canonical"
        }
      ]}
      meta={[
        {
          name: `title`,
          content: title,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: sharingDescription,
        },
        {
          property: `og:image`,
          content: `http:${sharingImage}`,
        },
        {
          property: `og:url`,
          content: site.siteMetadata.siteUrl + currentUrl,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: sharingDescription,
        },
        {
          name: `twitter:image`,
          content: sharingImage,
        },
        {
          name: `twitter:image:alt`,
          content: title,
        }
      ]
        .concat(
          (keywords !== null && keywords.length > 0)
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )}
    />
  )
}

Seo.defaultProps = {
  lang: `de`,
  title: '',
  metaTitle: '',
  metaDescription: '',
  sharingTitle: '',
  sharingDescription: '',
  sharingImage: '',
  currentUrl: '',
  keywords: [],
}
export default Seo
