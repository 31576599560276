import React from 'react'
import ReactMarkdown from 'react-markdown'
import breaks from 'remark-breaks'
import gfm from 'remark-gfm'
import { wrapper } from './renderer.module.scss'

const TextRenderer = ({ text='' }) => {
  return (
    <div className={wrapper}>
      <ReactMarkdown children={text} plugins={[breaks, gfm]}/>
    </div>
  )
}

export default TextRenderer
