import { active, navigation, navigationItem, wrapper } from './navigation.module.scss'
import { graphql, useStaticQuery } from "gatsby"

import Bar from '../bar'
import { Link } from 'gatsby'
import React from "react"

const HeaderNavigation = () => {
  const { mainNavigation } = useStaticQuery(
    graphql`
      {
        mainNavigation: allMainJson {
          edges {
            node {
              link
              label
            }
          }
        }
      }
    `
  )

  return (
    <div className={wrapper}>
      <nav className={navigation}>
        {mainNavigation?.edges.map(({ node: item }) => (
          <Link key={item.link} to={item.link} className={navigationItem} activeClassName={active}>
            {item.label}
            <Bar/>
          </Link>
        ))}
      </nav>
    </div>
  )
}

export default HeaderNavigation
