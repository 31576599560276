// extracted by mini-css-extract-plugin
export var contact = "index-module--contact--Q5SgW";
export var container = "index-module--container--qdrnP";
export var copyright = "index-module--copyright--DLTPC";
export var fadein = "index-module--fadein--NNweW";
export var footer = "index-module--footer--uojx8";
export var infos = "index-module--infos--4olYE";
export var legal = "index-module--legal--OACvX";
export var link = "index-module--link--Uhx06";
export var meta = "index-module--meta--oNbRr";
export var navigation = "index-module--navigation--P3a8V";
export var navigationItem = "index-module--navigationItem--zHOcQ";