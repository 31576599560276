import "@fontsource/roboto-mono/400.css"
import "@fontsource/roboto-mono/500.css"
import "@fontsource/roboto-mono/700.css"

import CookieBanner from "../components/cookie-banner"
import Footer from "../components/footer"
import Header from "../components/header"
import React from "react"
import { main } from './index.module.scss'

const Layout = ({ children }) => (
  <>
    <Header/>
    <main className={main}>{children}</main>
    <Footer/>
    <CookieBanner/>
  </>
)

export default Layout
